import { CustomFieldSelection, Household, CustomField as HouseholdCustomField, HouseholdObjective } from '../entities/household';
import { Injectable, signal } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { PdfSection, TableContent } from '../entities/pdf-content';
import { createGuid, isGuid, isNullOrUndefined, one, replaceOrAdd, throwError, unique } from '../shared/util';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { JourneyMilestoneService } from './journey-milestone.service';
import { JourneyObjective } from '../entities/journey-objective';
import { JourneyService } from './journey.service';
import { MasterDataService } from './master-data.service';
import { NameValue } from '../entities/name-value';
import { Pages } from '../enums/page';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import { PdfService } from './pdf.service';
import { SessionTypes } from '../enums/session-type';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class JourneyObjectiveService {

  constructor(
    private http: HttpClient,
    private householdService: HouseholdService,
    private journeyService: JourneyService,
    private readonly journeyMilestoneService: JourneyMilestoneService
  ) { }

  readonly orderIncrementValue = 1;
  journeyObjectives = signal<JourneyObjective[]>([]);

  getJourneyObjectives(previousJourneyInstanceID?: string): Observable<JourneyObjective[]> {
    const journeyID = this.journeyService.getNonNullableJourney().journeyID;
    const journeyInstanceId = previousJourneyInstanceID ?? this.journeyService.getNonNullableJourney().journeyInstanceID;
    return this.http.get<JourneyObjective[]>(`/api/journeyobjectives/${journeyID}/${journeyInstanceId}/latest`);
  }

  saveJourneyObjectives(journeyObjectives: JourneyObjective[]): Observable<JourneyObjective[]> {
    return this.http.post<JourneyObjective[]>("/api/journeyobjectives/", journeyObjectives);
  }

  async updateJourneyObjective(journeyObjective: JourneyObjective): Promise<void> {
    this.journeyObjectives
      .update(x => [...x.filter(y => y.objectiveID !== journeyObjective.objectiveID), journeyObjective].sort((a, b) => Number(a.order) - Number(b.order)));
    await firstValueFrom(this.saveJourneyObjectives([journeyObjective]));
  }

  async addJourneyObjective(journeyObjective: JourneyObjective): Promise<void> {
    journeyObjective.objectiveID = createGuid();
    this.journeyObjectives.update(x => [...x, journeyObjective]);
    await firstValueFrom(this.saveJourneyObjectives([journeyObjective]));
  }

  async deleteJourneyObjective(journeyObjective: JourneyObjective): Promise<void> {
    journeyObjective.isDeleted = true;
    this.journeyObjectives.update(x => x.filter(y => y.objectiveID !== journeyObjective.objectiveID));
    await firstValueFrom(this.saveJourneyObjectives([journeyObjective]));
  }

  async refresh(): Promise<void> {
    if (this.journeyService.getNonNullableJourney().sessionType === SessionTypes.NewMeeting.toString()) {
      await this.refreshFromAos();
    } else {
      const previousJourneyInstanceId = this.journeyService.previousJourney?.journeyInstanceID;
      if (isNullOrUndefined(previousJourneyInstanceId)) {
        throwError("Failed to obtain the journeyInstanceId of the previousJourney. Since the journeySession type is now 'NewMeeting' it must have a value.");
      }
      await this.loadJourneyObjectives(previousJourneyInstanceId);
    }
  }

  private async refreshFromAos() {
    if (!this.householdService.household?.clientObjectives) return;
    const journeyObjectives = this.householdService.household.clientObjectives.sort((a, b) => Number(a.id) - Number(b.id)).map((x, index) => this.createJourneyObjectives((index + this.orderIncrementValue), x));
    this.journeyObjectives.set(journeyObjectives);

    if (journeyObjectives[0]) {
      await firstValueFrom(this.saveJourneyObjectives(journeyObjectives));
    }
  }

  createJourneyObjectives(order: number, householdObjective?: HouseholdObjective): JourneyObjective {
    return {
      journeyID: this.journeyService.getNonNullableJourney().journeyID,
      created: moment().utc().toDate(),
      lastModified: moment().utc().toDate(),
      journeyInstanceID: this.journeyService.getNonNullableJourney().journeyInstanceID,
      isDeleted: false,
      objectiveID: householdObjective?.id ? householdObjective.id.toString() : createGuid(),
      objectiveStatus: householdObjective?.objectiveStatusID ? MasterDataService.getObjectiveStatuses().find(x => x.iD === householdObjective.objectiveStatusID)?.name : null,
      category: householdObjective?.category,
      categoryID: householdObjective?.categoryID,
      excludedSubCategories: [],
      objectiveStatusID: householdObjective?.objectiveStatusID,
      text: householdObjective?.text,
      timeFrame: householdObjective?.timeFrameID ? MasterDataService.getTimeframes().find(x => x.iD === householdObjective.timeFrameID)?.name : null,
      timeFrameID: householdObjective?.timeFrameID,
      comment: householdObjective?.comment,
      order
    };
  }

  async updateJourneyObjectivesToAos(): Promise<void> {
    if (!this.householdService.household) return;

    this.householdService.household.clientObjectives = this.journeyObjectives()
      .map(x => ({
        id: isGuid(x.objectiveID) ? null : Number(x.objectiveID),
        category: x.category,
        categoryID: x.categoryID,
        objectiveStatusID: x.objectiveStatusID,
        text: x.text,
        timeFrameID: x.timeFrameID
      }));

    await this.updateCustomFields(this.householdService.household);
  }

  updateOrderValue(): void {
    this.journeyObjectives().forEach((x, index) => {
      x.order = (index + this.orderIncrementValue);
    });
  }

  private async updateCustomFields(household: Household): Promise<void> {
    const objectivePageReached = !isNullOrUndefined(await firstValueFrom(this.journeyMilestoneService.getMilestoneForPage(Pages.SetYourObjectives)));

    if (!objectivePageReached) {
      // We don't want to update the custom fields if the user hasn't reached the set your objectives page.
      return;
    }

    const newCustomFieldValuesComment: HouseholdCustomField[] = [];
    const newCustomFieldSelections: CustomFieldSelection[] = [];

    const adviceDetails = this.getAdviceDetails();
    for (const masterDateItem of MasterDataService.getCategories()) {

      const adviceItem = adviceDetails.outOfScope.find(x => x.categoryId === masterDateItem.iD);

      const comment = adviceItem
        ? adviceItem.reasonText
        : "";

      const excludedSubCategoryIds = adviceItem
        ? adviceItem.subCategoryIds ?? []
        : [];

      newCustomFieldValuesComment.push({
        value: comment,
        customFieldID: masterDateItem.commentCustomFieldId
      });

      newCustomFieldSelections.push(
        {
          customFieldID: masterDateItem.excludedAdviceCustomFieldId,
          selectedFieldItems: excludedSubCategoryIds.map(x => ({ itemID: x, itemName: null }))
        }
      )
    }

    replaceOrAdd(household.householdCustomFields, (a, b) => a.customFieldID === b.customFieldID, newCustomFieldValuesComment);
    replaceOrAdd(household.householdCustomFieldSelections, (a, b) => a.customFieldID === b.customFieldID, newCustomFieldSelections);
  }

  getAdviceDetails(): {
    include: AdviceItem[];
    outOfScope: AdviceItem[];
  } {
    const result: {
      include: AdviceItem[];
      outOfScope: AdviceItem[];
    } = {
      include: [],
      outOfScope: []
    };

    const distinctCategoryIDs = unique(this.journeyObjectives().map(x => x.categoryID));

    for (const categoryID of distinctCategoryIDs.sort((a, b) => Number(a) - Number(b))) {
      const objectives = this.journeyObjectives().filter(x => x.categoryID === categoryID);
      const outOfScope = objectives.flatMap(x => x.excludedSubCategories ?? [])

      if (outOfScope[0]) {
        result.outOfScope.push({
          categoryName: MasterDataService.getCategories().find(x => x.iD === categoryID)?.name,
          subCategories: unique(outOfScope.map(x => String(x.name))),
          subCategoryString: unique(outOfScope.map(x => String(x.name))).join(", "),
          subCategoryIds: unique(outOfScope.map(x => x.id)),
          reasonText: objectives.filter(x => !isNullOrUndefined(x.comment) && x.comment.trim() !== "").map(x => x.comment).join("; "),
          categoryId: categoryID
        });
      }

      const included = MasterDataService.getSubCategories().filter(x => x.parentID === categoryID);

      if (included[0]) {
        result.include.push({
          categoryName: MasterDataService.getCategories().find(x => x.iD === categoryID)?.name,
          subCategories: included.map(x => x.name),
          subCategoryString: included.map(x => String(x.name)).join(", "),
          reasonText: "",
          categoryId: categoryID
        });
      }
    }

    return result;
  }

  getPdfSections(): PdfSection[] {
    const pdfSections: PdfSection[] = [];

    for (const [index, item] of this.journeyObjectives().sort((a, b) => a.order - b.order).entries()) {
      const pdfSection: PdfSection = {
        breakLine: false,
        pdfSectionType: PdfSectionTypes.Table,
        title: `Objective ${index + one}`,
        content: {
          tableHeaders: [
            {
              name: "Field",
              width: "30%"
            },
            {
              name: "",
              width: "60%"
            }
          ],
          tableRows: []
        }
      };

      const nameValues = JourneyObjectiveService.getJourneyObjectiveFields(item);

      (pdfSection.content as TableContent).tableRows.push(...nameValues.map(x => PdfService.getTableRow(x)));

      pdfSections.push(pdfSection);
    }    

    return pdfSections;
  }

  async loadJourneyObjectives(previousJourneyInstanceID: string): Promise<void> {
    const items = await firstValueFrom(this.getJourneyObjectives(previousJourneyInstanceID));

    const now = moment().utc().toDate();
    for (const item of items) {
      item.journeyInstanceID = this.journeyService.getNonNullableJourney().journeyInstanceID;
      item.lastModified = now;
      item.created = now;
    }

    await firstValueFrom(this.saveJourneyObjectives(items));

    this.journeyObjectives.set(items.sort((a, b) => a.order - b.order));
  }

  private static getJourneyObjectiveFields(journeyObjective: JourneyObjective): NameValue[] {
    const isSame = moment(journeyObjective.created).isSame(journeyObjective.lastModified);

    return [
      {
        name: "Description",
        value: journeyObjective.text,
      },
      {
        name: "Advice topic",
        value: journeyObjective.category,
      },
      {
        name: "Timeframe",
        value: journeyObjective.timeFrame
      },
      {
        name: "Status",
        value: journeyObjective.objectiveStatus
      },
      {
        name: "Priority",
        value: journeyObjective.order
      },
      {
        name: "Not included",
        value: journeyObjective.excludedSubCategories?.map(x => x.name).join(", ")
      },
      {
        name: "Reason this is not included and impact of this",
        value: journeyObjective.comment
      },
      {
        name: "Created",
        value: moment(journeyObjective.created).format("DD/MM/yyyy HH:mm:ss")
      },
      {
        name: "Modified",
        value: isSame ? "-" : moment(journeyObjective.lastModified).format("DD/MM/yyyy HH:mm:ss"),
      }
    ];
  }
}

export interface AdviceItem {
  categoryName?: string;
  categoryId: number | null | undefined
  subCategories: string[];
  subCategoryString: string;
  subCategoryIds?: number[];
  reasonText: string;
}
